<template>
  <div style="height: 20px;">
    <div style="width: 100px;display: inline-block;font-family: cabin_sketch_regular">{{ tradLibelle === null ? '' : $t(tradLibelle) }} {{ rounds_cur }}/{{ rounds_total }}</div>
    <BarreProgression
      style="margin-bottom: 2px;display: inline-block;width: calc(100% - 100px)"
      :step="(rounds_cur * 2 - (salon_etat === 'e2' ? 1 : 0)) - 1"
      :steps="(rounds_total * 2) - 1"
    />
  </div>
</template>

<script>
export default {
  name: 'BarreRounds',

  computed: {
    tradLibelle() {
      if (this.salon_etat === 'e2')
        return 'sketchy.page_jeu.barre_rounds.dessin';
      if (this.salon_etat === 'e3')
        return 'sketchy.page_jeu.barre_rounds.mot';
      return null;
    },
  },

  components: {
    BarreProgression: () => import('./BarreProgression'),
  },

  props: ['salon_etat', 'rounds_cur', 'rounds_total'],
}
</script>
